<template>
  <div class="row">
    <div class="col-12">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <ModalVideo ref="modalVideo"></ModalVideo>
      <Confirm ref="confirm" :method="store"/>
      <transition name="slide">
        <div class="card border-warning">
          <div class="card-header">
            <strong class="text-primary">
              Cambiar contraseña del estudiante
            </strong>
            <div class="card-header-actions">
              <button class="btn btn-outline-info"
                      title="Ver video de ayuda"
                      @click="$refs.modalVideo.mostrarVideo('video-alumno-nuevo.mp477777777777777777')">
                <font-awesome-icon icon="fas fa-question-circle"/>
                Ayuda
              </button>
            </div>
          </div>
          <div class="card-body">
            <form ref="frmNuevo" novalidate>
              <div class="row">
                <div class="col-3">
                  <label for="password">Código de Alumno: </label>
                </div>
                <div class="col-6">
                  {{ userpass.codigo_alumno }}<br/>
                  <em class="text-muted">Última modificación: {{ ultima_modificacion }}</em>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-3">
                  <label for="password">Nueva contraseña: </label>
                </div>
                <div class="col-4">
                  <input id="password" v-model="userpass.password" class="form-control" required="required"
                         type="password"/>
                  <b>8</b> caracteres, Mayúsculas, Minúsculas, Simbolos
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-3">
                  <label for="password_confirmation">Repetir nueva contraseña: </label>
                </div>
                <div class="col-4">
                  <input id="password_confirmation" v-model="userpass.password_confirmation" class="form-control"
                         required="required" type="password"/>
                </div>
              </div>
              <hr/>
              <button class="mr-2 btn btn-primary" type="button" @click="store()">Cambiar contraseña</button>
              <button class="btn btn-danger" type="button" @click="goBack">Regresar a la pantalla anterior</button>
            </form>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";
import ModalVideo from "@/views/notifications/ModalVideo";
import Confirm from "@/views/notifications/Confirm";
import Table from "@/views/base/Table";

var modalPdf;

export default {
  name: 'EditUser',
  components: {Table, Confirm, ModalVideo, Alerts, Toast},
  props: {
    caption: {
      type: String,
      default: 'User id'
    },
  },
  data: () => {
    return {
      userpass: {
        codigo_alumno: 0,
        password: '',
        password_confirmation: '',
      },
      nombreusuario: '',
      ultima_modificacion:''
    }
  },
  methods: {
    goBack() {
      this.$router.push({path: '/estudiante/'});
    },
    store() {
      if (!modalPdf.$refs.frmNuevo.checkValidity()) {
        modalPdf.$refs.frmNuevo.classList.add('was-validated');
      } else {
        modalPdf.$refs.alert.show('Cambiando contraseña');
        axios.post(this.$apiAdress + '/api/estudiante/password?token=' + localStorage.getItem("api_token"),
            modalPdf.userpass
        )
            .then(function (response) {
              modalPdf.$refs.mensajeToast.makeToast('', 'Contraseña cambiada satisfactoriamente.', 'success');
              modalPdf.$router.push({path: '/estudiante/'});
            })
            .catch(function (error) {
              modalPdf.$refs.alert.hide();
              modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
            });
      }
    },
  },
  mounted: function () {
    modalPdf = this;
    modalPdf.sedeSeleccionada = localStorage.sedeSeleccionada;
    modalPdf.userpass.codigo_alumno = modalPdf.$route.params.codigo_alumno
    axios.get(this.$apiAdress + '/api/estudiante/' + localStorage.sedeSeleccionada + '/' + modalPdf.$route.params.codigo_alumno + '/estudiante?token=' + localStorage.getItem("api_token"))
        .then(function (response) {
          modalPdf.ultima_modificacion=response.data.ultima_modificacion
          modalPdf.userpass.usuario_id = response.data.usuario_id;
          modalPdf.nombreusuario = response.data.nombreusuario;
        })
        .catch(function (error) {
          modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
        });
  }
}

</script>
<style>